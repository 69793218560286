@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --primary-black: #222;
}

* {
    color: var(--primary-black);
    font-size: 18px;
    font-family: 'MyanmarSansPro';
    box-sizing: border-box;
}

html, body 
{
    position: relative;
    padding: 0;
    margin: 0;
}

.t-gray 
{
    color: #1B1B1B !important;
}


.page 
{
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: white;
}

label
{
    cursor: pointer;
}

.input-required::after {
    content: '*';
    color: red;

}

.x-input 
{
    width: 100%;
    height: 49px;
    padding: 13px 16px;
    font-size: 18px;
    background: #FFFFFF;
    border: 1px solid rgba(119, 119, 119, 1);
    border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
    background-image: url('../src/img/Calendar.png');
}

.x-label {
    margin-bottom: 12px;
    font-size: 18px;
    color: var(--primary-black);
    display: block;
}

.x-input:focus {
    outline: none;
    border: 1px solid var(--primary-black);
}

a {
    text-decoration: none;
}

.x-checkbox {
    width: 18px;
    height: 18px;
    cursor: pointer;
    
}


.popup-render  {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #4e4e4e90;
    display: flex;
    justify-content: center;
    align-items: center;
}
