.dashboard-layout {
    width: 100%;
    display: flex;
    
}

.side-nav {
    position: relative;
    max-width: 370px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: #FAFAFA;
    border-right: 1.5px solid rgba(0, 0, 0, 0.1);
}

.sub-layout {
    width: 100%;
    overflow-x: hidden;
}

.topbar {
    display: flex;
    height: 95px;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
}

.topbar .back-icon {
    width: 16px;
    height: 29px;
    margin-left: 38px;
}

.topbar .user-profile {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
    position: relative;
    margin-right: 25px;
    cursor: pointer;
}

.topbar .user-profile .down-icon {
    width: 13px;
    height: auto;
}
.topbar .user-profile .profile-pic {
    width: 46px;
    height: 46px;
    border-radius: 50%;
}


.topbar .user-profile span {
    text-align: end;
}
.topbar .user-profile span.username {
    font-size: 15px;
}

.topbar .user-profile span:not(.username) {
    font-size: 13px;
    color: rgba(34, 34, 34, 0.702);
}


.dashboard-logo {
    display: flex;
    justify-content: center;
    margin-top: 28px;
}
.dashboard-button {
    position: absolute;
    top: 28px;
    right: 18px;
    width: 30px;
    height: auto;
}

.dashboard-logo .app-icon{
    width: 177px;
    height: auto;
}

.navigation 
{
    padding: 0 46px 0 38px;
    flex-grow: 1;
    margin-top: 43px;
}

.nav-item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--primary-black);
    position: relative;
}

.nav-item, .nested-nav-item {
    text-decoration-line: none;
    margin-bottom: 31px;
}

.nav-item:not(.active), .parent-nav:not(.active) {
    opacity: .5;
}

.nav-item img, .nested-nav-item img {
    height: 18px;
    width: auto;
}

.nested-nav-item {
    position: relative;
}

.nested-nav-item .downarrow {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.parent-nav.active .downarrow {
    transform: translateY(-50%) rotateZ(180deg);
}

.nav-item.active::before, .parent-nav.active::before {
    /* content: '';
    display: block;
    width: 5px;
    height: 50px;
    background-color: var(--primary-black);
    position: absolute;
    top: 50%;
    left: -38px;
    transform: translateY(-50%); */
}

.parent-nav
{
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 20px;
    color: var(--primary-black);
}

.child-nav .sub-nav-item {
    text-decoration: none;
    display: block;
    padding: 0 38px;
    margin-top: 34px;
    color: var(--primary-black);
}

.child-nav .sub-nav-item:not(.active) {
    opacity: .5;
}

.user-profile-section {
    flex-shrink: 0;
    padding-bottom: 40px;
}


.user-profile:hover {
    opacity: 1;
    /* background-color: rgba(0, 0, 0, 0.07); */
}

.profile-popup 
{
    position: absolute;
    right: 0px;
    bottom: 0;
    transform: translateY(100%);
    padding: 10px;
    display: none;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.202);
}

.profile-popup p {
    font-size: 16px;
}

.profile-popup img 
{
    width: 18px;
    height: 18px;
}

.profile-popup>div {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 18px;
}

.profile-popup>div:hover {
    background-color: rgba(0, 0, 0, 0.07);
}

.user-profile:hover .profile-popup
{
    display: block;
}

.profile-image 
{
    width: 47px;
    height: 47px;
}

.user-profile-divider {
    margin: 0 auto;
    width: 242px;
    height: 2px;
    background-color: black;
    opacity: .05;
}

.user-detail
{
    flex-grow: 1;
    padding-left: 18px;
    padding-right: 10px;
}

.user-detail span {
    display: block;
}

.logout-image 
{
    width: 20px;
    height: 20px;
}

.user-detail .name {
    font-size: 18px;
    margin-bottom: 6px;
}

.user-detail .role {
    font-size: 14px;
}

