
.login-section
{
	max-width: 1080px;
	width: 100%;
	padding: 80px 71px 69px 88px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}


.title 
{
	font-size: 30px;
	color: black;
}


.login-section .container{
	display: flex;
	align-items: center;

}

.left-side
{
	padding-right: 92px;
	height: 100%;

}

.logo 
{
	/* width: 229px;
	height: 229px; */
	width: 270px;
	height: auto;
    margin-bottom: 32px;
}

.divider
{
	width: 2px;
	height: 418px;
	background: rgba(0, 0, 0, 0.1);
}

.right-side
{
    flex-grow: 1;
    padding-left: 70px;
}

.right-side h1 
{
    font-size: 30px;
    margin-bottom: 32px;
}

.input-item label 
{
    display: block;
    font-size: 18px;
    color: #1B1B1B;
    opacity: .45;
}

.input-item input 
{
    margin-top: 15px;
    width: 100%;
    height: 50px;
    padding-left: 16px;
    /* opacity: .45; */
    font-size: 18px;
    border: 1px solid rgba(27, 27, 27, 0.4);
    border-radius: 5px;
}

.input-item input:focus 
{
    outline: none;
    border-color: rgba(0, 0, 0, 0.77);
}

.radio-item 
{
    margin-top: 25px;
}

.radio-item input 
{
    width: 12px;
    height: 12px;
    background-color: white;
    position: relative;
    appearance: none;
    border: 1px solid #00000097;
    border-radius: 50%;
}

.radio-item label
{
    margin-left: 9px;
    margin-right: 23px;
    font-size: 18px;
    opacity: .45;
    color: #1B1B1B;
}

.radio-item input:checked + label
{
    opacity: 1;
}

.radio-item input:checked::before {
    content: "";
    display: block;
    width: 6.45px;
    height: 6.45px;
    border-radius: 50%;
    background-color: #1B1B1B;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



.button-container
{
    margin-top: 35px;
    display: flex;
    gap: 20px;
    justify-content: end;
}

.button-container button
{
    width: 155px;
    padding: 13px 0;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.cancel-btn 
{
    background: #F5F5F5;
    color: #1F1F1F66;

}

.login-btn
{
    background-color: var(--primary-black);
    color: #FAFAFA;

}

.login-section .sponsor-logo {
    max-width: 240px;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: -67px;
    left: 50%;
    transform: translateX(-50%);
}